.gospel-container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  .image-container {
    flex: 1;
    text-align: center;
  }
  
  .gospel-image {
    max-width: 100%;
    height: 500px;
  }
  
  .text-container {
    flex: 1;
    padding: 0 20px;
    padding-top: 20px;
  }
  @media (max-width: 768px) {
    .gospel-container {
      flex-direction: column;
    }
    .description-title{
        text-align: center;
        font-weight: 300;
       
    }
    .description-content {
        width: 90%;
    } 
  
    .image-container,
    .text-container {
      width: 100%;
    }
  
    .gospel-image {
      height: 400px; 
      width: 80%;/* Adjust the height as per your requirement for small screens */
    }
  }
  