:root {
  --font-base: 'Cormorant Upright', serif;
  --font-alt: 'Open Sans', sans-serif;
  
  --color-golden: #DCCA87;
  --color-black: #0C0C0C;
  --color-gray: #545454;
  --color-crimson: #F5EFDB;
  --color-grey: #AAAAAA;
  --color-white: #FFFFFF;
  --base-color: #3377FF;
}
#footer {
  background: #060827;
  color: #AAAAAA;
  height: 300px;
  border-radius: 3px;
  text-align: center;

  
}
.followme-title{
  padding-top: 20px;
}
.phonenumber{
  color: white;
}
.text {
  padding-top: 60px;
}
.maker-name a {
  color: #3174e0;
   text-decoration: none;
}
.maker-name a:hover {
  color: #193e79;
}
.social-media a {
  color: #3174e0;
  text-decoration: none;
  padding: 3px;
}
@media screen and(max-width:1000px) {

}