:root {
    --font-base: 'Cormorant Upright', serif;
    --font-alt: 'Open Sans', sans-serif;
    
    --color-golden: #DCCA87;
    --color-black: #0C0C0C;
    --color-gray: #545454;
    --color-crimson: #F5EFDB;
    --color-grey: #AAAAAA;
    --color-white: #FFFFFF;
    --base-color: #3377FF;
  }

.app {
    text-align: center;
    margin-top: 50px;
  }
  
  .app__title {
    font-size: 24px;
    font-weight: bold;
    color: #dcc05a;
    margin-bottom: 20px;
  }
  .app__title :hover{
    border-bottom: 2px solid black;
    color: #AAAAAA;
  }
  .app__testimonials {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .app__testimonial {
    width: 300px;
    padding: 20px;
    margin: 10px;
    background-color: #f4f4f4;
    border-radius: 5px;
  }
  
  .app__testimonial-quote {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
  }
  
  .app__testimonial-name {
    font-size: 14px;
    font-weight: bold;
    color: #666;
    margin-top: 10px;
  }
  .testimonials-photo {
    width: 80px; 
    height: 80px;
  
    border-radius: 50%; 
  }